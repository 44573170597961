<template>
  <section class="section container">
    <h1>Meus Cursos</h1>
    <!-- <div class="level">
      <div class="level-left">
        <router-link tag="span" to="/cursos">Comprar cursos</router-link>
      </div>
      <div class="level-right">
        <router-link tag="span" to="/cursos">
          <b-icon icon="shopping-cart" size="is-small"></b-icon>
        </router-link>
      </div>
    </div>-->

    <hr />

    <!-- :style=" ? 'opacity: .5' : ''" -->
    <div
      :class="[
        course.completed ? 'box-green' : '',
        'box',
        !course.active ? 'transp' : '',
      ]"
      v-for="course in courses"
      :key="course._id"
    >
      <div class="icon-custom" v-if="!course.active">
        <b-icon pack="fa" icon="lock"></b-icon>
      </div>
      <div class="icon-custom green" v-if="course.completed && course.active">
        <b-icon pack="fa" type="is-success" icon="check"></b-icon>
      </div>
      <div class="columns">
        <div class="column is-10 img opacity-custom">
          <img :src="course.course.instructor.image" alt="imagem professor" />
          <div>
            <router-link
              v-if="course.active"
              :to="{
                name: 'userCourseUser',
                params: { name: course.course._id },
              }"
              tag="h3"
              >{{ course.course.name }}</router-link
            >
            <h4 class="has-text-weight-bold" v-else>
              {{ course.course.name }}
            </h4>
            <span>{{ "Professor: " + course.course.instructor.name }}</span>

            <router-link
              v-if="course.active"
              :to="{
                name: 'userCourseUser',
                params: { name: course.course._id },
              }"
              class="icon-control animated infinite pulse slow"
            >
              <b-icon
                class
                pack="fa"
                type="is-success"
                icon="arrow-circle-right"
              ></b-icon>
              <span>Acessar curso</span>
            </router-link>

            <a
              v-if="course.completed && course.hasCertification"
              :href="url"
              target="_blank"
              rel="noopener noreferrer"
              class="icon-control link-redirect animated infinite pulse slow"
            >
              <b-icon
                class
                pack="fa"
                type="is-success"
                icon="arrow-circle-right"
              ></b-icon>
              <span>Acessar Certificação</span>
            </a>
          </div>
        </div>

        <div class="column medalha">
          <div v-if="course.completed">
            <img src="@/assets/img/medalhaOn.png" alt="imagem medalha" />
            <span style="color: #597aff">{{ course.examMark }}</span>
            <p @click="certificado(course)" class="link">Certificado Curso</p>
          </div>
          <div class="off" v-else>
            <img src="@/assets/img/medalhaOff.png" alt="imagem medalha" />
          </div>
        </div>
      </div>

      <div class="bar-progress opacity-custom ">
        <span>
          {{ course.progress ? course.progress.toFixed(2) + "%" : "0%" }}
        </span>
        <div
          :style="
            course.progress ? 'width:' + course.progress.toFixed(2) + '%' : ''
          "
        ></div>
      </div>
    </div>

    <div v-show="coursenull">
      <h1 class="oops">
        <b>OOPS!</b> você não tem nenhum curso,
        <router-link tag="span" to="/cursos">clique aqui</router-link>para
        comprar um curso!
      </h1>
    </div>
    <Certificado v-show="userCert" :user="userCert"></Certificado>
  </section>
</template>

<script>
import Axios from "axios";
import Certificado from "./Certificado";

const redirect_url = process.env.VUE_APP_REDIRECT_URL;

export default {
  components: { Certificado },
  data() {
    return {
      url: redirect_url,
      courses: [],
      user: [],
      coursenull: false,
      userCert: null,
    };
  },
  methods: {
    certificado(course) {
      Axios.get(`/v1/courses/info?_id=${course.course._id}`)
        .then((data) => {
          this.userCert = {
            nome: this.user.name,
            curso: course.course.name,
            hora: data.data.data[0].hourlyLoad,
            data: course.hora,
            exame: course.finalExam,
          };
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getCourseById(course, report) {
      Axios.get(`/v1/courses/${course._id}`)
        .then((data) => {
          this.userCert = {
            nome: this.user.name,
            curso: course.name,
            hora: data.data.hourlyLoad,
            data: report.finalizedAt,
            exame: report._id,
          };
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then((data) => {
          this.user = data.data;
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/enrolls/me`)
        .then((data) => {
          this.courses = data.data;
          if (!data.data || !data.data[0]) this.coursenull = true;
          this.getStatus(data.data);
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
    getStatus() {
      Axios.get(`/v1/user-status/me`)
        .then((data) => {
          for (let course of data.data) {
            this.courses.filter((item) => {
              if (course.enroll && course.enroll === item._id) {
                item.progress = course.progress;
                item.completed =
                  (course.lesson &&
                    course.lesson.length &&
                    course.lesson.length > 0 &&
                    course.lesson.find((e) => e.exam === course.finalExam)
                      .completed) ||
                  course.completed;
                item.examMark = course.examMark;
                item.finalExam = course.finalExam;
                item.hasCertification =
                  course.extra && course.extra.hasCertification;
                item.hora = course.finalizedAt
                  ? course.finalizedAt
                  : course.updatedAt;
              }
              return;
            });
          }
          this.$forceUpdate(this.courses);
        })
        .catch((erro) => {
          console.error(erro);
        });
    },
  },
  mounted() {
    this.getMe();
    this.getCourse();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.oops {
  span {
    color: #23d160;
    font-weight: bold;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.level {
  border: 0.1rem #3cb371 dashed;
  border-radius: 0.3rem;
  padding: 0.7rem;
  background-color: rgba($color: #3cb371, $alpha: 0.2);
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #3cb371;

  .level-left {
    span {
      transition: all 0.2s ease-in-out;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .level-right {
    span {
      width: 1.7rem;
      cursor: pointer;
    }
  }
}

.img {
  display: flex;
  align-items: center;

  img {
    border: #ccc 0.1rem solid;
    border-radius: 50%;
  }

  div {
    margin-left: 1rem;

    .has-text-weight-bold {
      font-size: 1.2rem;
    }

    h3 {
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.box-green {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #23d160;
  background-color: rgba($color: #23d160, $alpha: 0.2);
}

.transp {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #ccc;
  background-color: rgba($color: #23d160, $alpha: 0.05);

  .opacity-custom {
    opacity: 0.5;
  }
}

.box {
  position: relative;

  .icon-custom {
    position: absolute;
    right: -10px;
    top: -10px;
    border: #ccc solid 1px;
    border-radius: 50%;
    padding: 5px;
    width: 38px;
    height: 38px;

    & > * {
      position: absolute;
      right: 5.5px;
    }
  }

  .icon-control {
    margin-top: 1px;
    display: flex;
    width: fit-content;
    align-items: center;
    color: #fff;
    font-weight: bold;
    background-color: rgba($color: #3cb371, $alpha: 1);
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    .icon {
      margin-right: 5px;
    }
  }

  .link-redirect {
    margin-top: 10px;
  }

  .green {
    border-color: #23d160;
    background-color: #bbf8d1;

    .icon {
      z-index: 999;
    }
  }
}

.medalha {
  display: flex;
  align-items: center;
  text-align: center;

  .off {
    padding-left: 1rem;
  }

  & > div {
    position: relative;

    span {
      font-size: 1.2rem;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
    }

    p {
      color: rgb(95, 183, 255);
      text-transform: uppercase;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.bar-progress {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 1.5rem;
  background-color: rgba($color: #3cb371, $alpha: 0.4);

  span {
    color: #fff;
    font-weight: bold;
    position: absolute;
    left: 48%;
  }

  div {
    transition: 0.2s ease-in-out;
    background-color: #3cb371;
    width: 0%;
    height: 100%;
  }
}
</style>
